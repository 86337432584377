import {AbstractControllerException} from 'core/abstract/AbstractControllerException';
import {ReduxService} from 'core/redux/services/ReduxService';
import {wrapper} from 'core/redux/store';
import {TAppController, TAppInitialController} from 'core/redux/types';
import {ContextService} from 'core/services/ContextService';

/**
 * Враппер для `getServerSideProps`
 * Использовать для внедрения логики среднего слоя в серверный рантайм при запросе страницы
 * @param {TAppController<any>} cb
 */
export const wrappedServerSideProps = (cb: TAppController<any>) => {
    return wrapper.getServerSideProps((reduxStore) => {
        ReduxService.serverReduxStore = reduxStore;

        return async (ctx) => {
            ContextService.ctx = ctx;
            ContextService.res = ctx.res;
            ContextService.req = ctx.req;

            try {
                return await cb(reduxStore, ctx);
            } catch (exception) {
                if (exception instanceof AbstractControllerException) {
                    return exception.makeNextControllerObject();
                }

                throw exception;
            }
        };
    });
};

/**
 * Враппер для `getInitialPageProps`
 * Использовать для внедрения логики среднего слоя в серверный рантайм при запросе страницы
 * @param {TAppInitialController<any>} cb
 */
export const wrappedInitialProps = (cb: TAppInitialController<any>) => {
    return wrapper.getInitialPageProps((reduxStore) => {
        ReduxService.serverReduxStore = reduxStore;

        return async (ctx) => {
            ContextService.ctx = ctx;
            ContextService.res = ctx.res;
            ContextService.req = ctx.req;

            try {
                return await cb(reduxStore, ctx);
            } catch (exception) {
                if (exception instanceof AbstractControllerException) {
                    return exception.makeNextControllerObject();
                }

                throw exception;
            }
        };
    });
};
