import {RESPONSE_STATUS_CODE} from 'core/constants';
import {ICustomError} from 'core/types';
import {NextPageContext} from 'next/types';

const {NOT_FOUND} = RESPONSE_STATUS_CODE;

export const setResponse404StatusCode = (ctx: NextPageContext) => {
    if (ctx.res) {
        ctx.res.statusCode = NOT_FOUND;
    }
};

export const get404Exception = () => {
    const error: ICustomError = new Error('Not Found');
    error.statusCode = NOT_FOUND;

    return error;
};
