import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TCategoryListResponse, TPopularCategoriesListResponse} from 'modules/categories/models/CategoryListResponse';
import {
    actionSetCategoryList,
    actionSetPopularCategoryList,
    actionSetPreviewProductList,
} from 'modules/categories/slice';
import {ICategoriesState} from 'modules/categories/types';
import {actionLoaderSetHidden, actionLoaderSetVisible} from 'modules/loader/slice';
import {Category} from 'new-models';

const DISPLAYED_PRODUCTS_NUMBER = 11;

export const getCategoriesList: TAppThunk<void> = async (dispatch, _, {categoryService}) => {
    const data = await dispatch(
        requestAction<TCategoryListResponse>({
            requestCb: () => categoryService.getCategoriesList(),
            type: actionSetCategoryList.type,
        })
    );

    dispatch(actionSetCategoryList(data.categories || []));
};

export const getPopularCategoryList: TAppThunk<void> = async (dispatch, _, {categoryService}) => {
    const data = await dispatch(
        requestAction<TPopularCategoriesListResponse>({
            requestCb: () => categoryService.getPopularCategoriesList(),
            type: actionSetPopularCategoryList.type,
        })
    );

    dispatch(actionSetPopularCategoryList(data.categories || []));
};

export const getPreviewProductsPerCategory =
    (sectionIdList: Category['id'][]): TAppThunk<void> =>
    async (dispatch, _, {productsService}) => {
        dispatch(actionLoaderSetVisible(actionSetPreviewProductList.type));

        const productPerCategoryObjectList = await Promise.all(
            sectionIdList.map((categoryId) => {
                return new Promise<ICategoriesState['productPreviewList']>((resolve) => {
                    const searchParams = new URLSearchParams();
                    searchParams.set('filter[categoryId]', categoryId);

                    productsService
                        .getProductList(searchParams, {
                            limit: DISPLAYED_PRODUCTS_NUMBER,
                        })
                        .then(({data}) =>
                            resolve({
                                [categoryId]: data?.products,
                            })
                        );
                });
            })
        );

        const productsPerCategoryList = productPerCategoryObjectList.reduce(
            (acc, productItem) => ({
                ...acc,
                ...productItem,
            }),
            {}
        );

        dispatch(actionSetPreviewProductList(productsPerCategoryList));
        dispatch(actionLoaderSetHidden(actionSetPreviewProductList.type));
    };
