import {RESPONSE_STATUS_CODE} from 'core/constants';
import {get404Exception} from 'core/exceptions/NotFoundException';
import {TAppInitialController} from 'core/redux/types';
import {CATEGORY_LEVEL} from 'modules/categories/constants';
import {getCategoryLevelHelper} from 'modules/categories/helpers';
import {selectCategoryNodeById} from 'modules/categories/selectors';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {getCategoriesList, getPreviewProductsPerCategory} from 'modules/categories/thunks';
import {ROUTER_QUERY_KEY} from 'modules/router/constants';
import {CATALOG_ROUTE} from 'routing/constants';

export const categoryProductListController: TAppInitialController<void> = async (store, ctx) => {
    const {query} = ctx;

    const {categoryCode, categoryId} = CategoryService.parseCategoryUrl(query[ROUTER_QUERY_KEY] as string);
    const categoryLevel = await getCategoryLevelHelper(store, ctx);

    if (!categoryId || !categoryCode || !categoryLevel) {
        return;
    }

    const {dispatch, getState} = store;
    const categoryNodeList = selectCategoryNodeById(categoryId)(getState());

    if (categoryLevel !== CATEGORY_LEVEL.second) {
        return;
    }

    if (categoryNodeList) {
        const categoryIdsList = categoryNodeList.childrenCategory.map((category) => category.id);

        await dispatch(getPreviewProductsPerCategory(categoryIdsList));
    }
};

export const categoriesController: TAppInitialController<void> = async (store, _) => {
    await store.dispatch(getCategoriesList);
};

export const categoriesRedirectController: TAppInitialController<void> = async (store, ctx) => {
    const {categoryId, categoryCode} = CategoryService.parseCategoryUrl(ctx.query[ROUTER_QUERY_KEY] as string);

    if (!categoryId) {
        throw get404Exception();
    }

    const {getState} = store;

    const categoryNode = selectCategoryNodeById(categoryId)(getState());
    if (!categoryNode) {
        throw get404Exception();
    }

    if (categoryCode !== categoryNode.code) {
        const trulyCategoryUrl = CategoryService.makeCategoryUrl(categoryNode.code, categoryNode.id);

        ctx.res
            ?.writeHead(RESPONSE_STATUS_CODE.FOUND, {
                location: `${CATALOG_ROUTE}/${trulyCategoryUrl}`,
            })
            .end();
    }
};
