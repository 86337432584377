import {TAppInitialController} from 'core/redux/types';
import {selectCategoriesList, selectCategoryBySectionId} from 'modules/categories/selectors';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {ROUTER_QUERY_KEY} from 'modules/router/constants';
import {Category} from 'new-models';

export const getCategoryNodeHelper: TAppInitialController<Category | null> = async (store, ctx) => {
    const {getState} = store;

    const categoryIdList = await getCategoryIdListHelper(store, ctx);

    if (!categoryIdList) {
        return null;
    }

    const currentId = categoryIdList.at(-1);

    if (!currentId) {
        return null;
    }
    const categoryNode = selectCategoryBySectionId(currentId)(getState());

    return categoryNode ?? null;
};

export const getCategoryIdListHelper: TAppInitialController<string[] | null> = async ({getState}, {query}) => {
    const {categoryId} = CategoryService.parseCategoryUrl(query[ROUTER_QUERY_KEY] as string);

    const idList = CategoryService.makeParentCategoryIdList(categoryId, [categoryId], selectCategoriesList(getState()));

    return idList || null;
};

export const getCategoryLevelHelper: TAppInitialController<number | null> = async (store, ctx) => {
    const categoryIdList = await getCategoryIdListHelper(store, ctx);

    return categoryIdList?.length || null;
};
